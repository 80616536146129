import React from 'react'
import { select_box, container, label, active } from './styles'

import { DeliveryTimeOptions } from '~/utils/options'

type Props = {
    time: string
    setTime: (e: React.ChangeEvent<HTMLSelectElement>) => void
    items: DeliveryTimeOptions[]
}

const DeliveryTimeForm = React.memo(({ time, setTime, items, ...props }: Props) => {
    return (
        <div css={[container, time !== '配送時間帯指定なし' && active]} {...props}>
            <label css={label}>配送時間帯</label>
            <select css={select_box} onChange={setTime}>
                {items.map(item => (
                    <option key={item} value={item}>{item}</option>
                ))}
            </select>
        </div>
    )
})

export default DeliveryTimeForm
