import React from 'react'
import {
	link_text
} from './styles'

type Props = {
	href: string
}

const MicroTextLink: React.FC<Props> = ({ href, children, ...props}) => {
	return (
		<a href={href} css={link_text} {...props}>
			{children}
		</a>
	)
}

export default MicroTextLink