import React, { FC } from 'react'

import styles from './styles'

type Props = {
  quantity: string
  onLeftButtonClick: () => void,
  onRightButtonClick: () => void,
}

const QuantityField: FC<Props> = ({ quantity, onLeftButtonClick, onRightButtonClick, ...props }) => (
  <div css={styles.containerClass} {...props}>
    <div onClick={onLeftButtonClick} css={styles.leftButtonClass}>-</div>
    <div css={styles.quantityTextClass}>{quantity}</div>
    <div onClick={onRightButtonClick} css={styles.rightButtonClass}>+</div>
  </div>
)

export default QuantityField