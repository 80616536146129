import { css } from '@emotion/core'
import { palette } from '~/utils/colors'

export const container = css`
	height: calc(100vh - 44px);
	width: 100%;
	z-index: 2;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	background: ${palette.grey['500']};
`

export const body = css`
	background: ${palette.common.white};
	padding: 20px;
`

export const heading = css`
    display: flex;
    justify-content: space-between;
`

export const list_item = css`
    margin-top: 10px;
`

export const hr = css`
    margin-top: 10px;
    border-color: ${palette.grey['500']};
`

export const option_hr = css`
	margin-top: 20px;
	border-color: ${palette.grey['500']};
`

export const text_empty = css`
    margin-top: 10px;
	text-decoration: underline;
    font-size: 1.4rem;
    font-weight: 500;
    cursor: pointer;

	:hover {
		color: ${palette.primary.light};
	}
`

export const postage = css`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
`

export const postage_text = css``

export const postage_price = css``

export const payment_button = css`
    margin-top: 20px;
`

export const total = css`
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
`

export const total_sub_text = css`
    font-weight: 500;
    color: ${palette.text.secondary};
`

export const text_note = css`
    margin-top: 10px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
`

export const text_note__attention = css`
    color: ${palette.text.primary};
`

export const delivery_date_form = css`
    margin-top: 6px;
`

export const delivery_time_form = css`
	margin-top: 6px;
`

export const select_text = css`
    color: ${palette.text.primary};
`

export const text_link = css`
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: 1.2rem;
    color: ${palette.primary.main};
`

export const recommend_lineitem = css`
    margin-top: 10px;
`

export const footer = css`
    background-color: ${palette.grey['500']};
    padding: 20px 20px 123px 20px !important;
`