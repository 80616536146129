import React from 'react'
import { Global, css } from '@emotion/core'

export const breakpoints = {
	tablet: 600,
	pc: 900,
	pc_header: 1024,
}

export const GlobalStyle = props => (
	<Global
		{...props}
		styles={css`
			html {
				font-size: 62.5%;
				height: 100%;
				min-width: 100%;
				overflow-x: hidden
			}

			* {
				box-sizing: border-box
			}

			body {
				font-family: 'Noto Sans JP', sans-serif;
				font-size: 1.4em;
				font-weight: bold;
				color: #707F89;
				line-height: 128%;
				height: 100%;
				-webkit-text-size-adjust: 100%;
			}

			main {
				display: block;
				padding-bottom: 40px
			}

			img {
				width: 100%;
				display: block
			}
		`}
	/>
)
