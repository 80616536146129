import React from 'react'
import { StylesProvider, createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

import ContextProvider from '~/provider/ContextProvider'
import { DrawerProvider } from '~/provider/DrawerProvider'
import { NavigationMenuProvider } from '~/provider/NavigationMenuProvider'

import Navigation from '~/components/organisms/Navigation'
import DrawerMenu from '~/components/organisms/DrawerMenu'
import Footer from '~/components/organisms/Footer'
import FooterBottom from '~/components/organisms/FooterBottom'
import AnnounceBar from '~/components/molecules/AnnounceBar'
import { GlobalStyle } from '~/utils/styles'

import '~/utils/typography.css'
import '~/utils/destyle.css'

import { palette } from '~/utils/colors'
import { typography } from '~/utils/typography'

import { announce_bar, header } from './styles'
import { footer } from '~/layouts/styles'


const theme = createMuiTheme({
    palette: palette,
    typography: typography,
    spacing: 10
})

const Layout: React.FC = ({ children }) => {
    return (
        <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>
                <ContextProvider>
                    <DrawerProvider>
                        <GlobalStyle />
                        <header css={header}>
                            <NavigationMenuProvider>
                                <Navigation />
                            </NavigationMenuProvider>
                        </header>
                        <main>
                            {children}
                        </main>
                        <footer css={footer}>
                            <Footer />
                            <FooterBottom />
                        </footer>
                    </DrawerProvider>
                </ContextProvider>
            </ThemeProvider>
        </StylesProvider>
    )
}

export default Layout
