import React, { useReducer } from 'react'
import { Action, NavigationMenuState, initialState, NavigationMenuContext } from '~/context/NavigationMenuContext'

const reducer = (state: NavigationMenuState, action: Action) => {
    switch (action.type) {
        case 'OPEN':
            return {
                ...state,
                isOpen: true
            }
        case 'CLOSE':
            return {
                ...state,
                isOpen: false
            }
        default:
            return state
    }
}

export const NavigationMenuProvider: React.FC = ({ children }) => {
    const [navigationMenuState, navigationMenuDispatch] = useReducer(reducer, initialState)

    return (
        <NavigationMenuContext.Provider value={{ navigationMenuState, navigationMenuDispatch }}>
            {children}
        </NavigationMenuContext.Provider>
    )
}