import React, { ComponentPropsWithRef } from 'react'
import BackgroundImage from 'gatsby-background-image'
import { FluidObject } from 'gatsby-image'

import Typography from '~/components/atoms/Typography'
import CVLink from '~/components/atoms/CVLink'

import { container, content, square, text_title } from './styles'

interface AppRecommendBannerProps extends ComponentPropsWithRef<"div"> {
    fluid: FluidObject
}

const AppRecommendBanner: React.VFC<AppRecommendBannerProps> = ({ fluid, ...props }) => {
    return (
        <div css={container} {...props}>
            <div css={square}>
                <BackgroundImage
                    css={content}
                    fluid={fluid}
                >
                    <Typography css={text_title} variant='h3'>入浴専門アプリで<br />ぽかぽか。<br />そしてリラックス。</Typography>
                    <CVLink url='/pages/app'>
                        無料でアプリを入手する
                    </CVLink>
                </BackgroundImage>
            </div>
        </div>
    )
}

export default AppRecommendBanner
