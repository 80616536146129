import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const container = css`
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid ${palette.common.white};
	@media (min-width: ${breakpoints.tablet}px) {
        padding-top: 40px;
        padding-bottom: 40px;
	}
`

export const logos_container = css`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`

export const logo = css`
    width: 40px;
`

export const copyright = css`
    margin-top: 20px;
    font-weight: 500;
`