import React, { FC, useContext, useState, useMemo, useCallback, useEffect } from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import moment from 'moment'
import StoreContext from '~/context/StoreContext'
import LineItem from '~/components/molecules/LineItem'
import { useQuantity } from '~/components/organisms/Navigation'
import CVButton from '~/components/atoms/CVButton'
import SenderNameForm from '~/components/molecules/SenderNameForm'
import DeliveryDateForm from '~/components/molecules/DeliveryDateForm'
import DeliveryTimeForm from '~/components/molecules/DeliveryTimeForm'
import { GIFT_TITLE } from '~/provider/ContextProvider'
import {
    body,
    container, delivery_date_form, delivery_time_form, footer, heading,
    hr,
    list_item, option_hr,
    payment_button,
    recommend_lineitem, select_text,
    text_empty, text_link,
    text_note, text_note__attention,
    total,
    total_sub_text
} from './styles'
import { DeliveryTimeOptions } from '~/utils/options'
import Typography from '~/components/atoms/Typography'
import MicroTextLink from '~/components/atoms/MicroTextLink'
import RecommendLineItem from '~/components/molecules/RecommendLineItem'
import FooterBottom from '~/components/organisms/FooterBottom'
import { DrawerContext } from '~/context/DrawerContext'

const timeOptions: DeliveryTimeOptions[] = ['指定なし', '午前中', '12時~14時', '14時~16時', '16時~18時', '18時~20時', '19時~21時']

const MIN_DELIVERY_DATE = moment(new Date()).add(7, 'd').toDate()

const DrawerMenu: FC = ({ ...props }) => {
    const { store, addAttribute, removeAllItems } = useContext(StoreContext)
    const [hasItems, quantity] = useQuantity()
    const [deliveryDate, setDeliveryDate] = useState<null | Date>(null)
    const [deliveryTime, setDeliveryTime] = useState<DeliveryTimeOptions>('指定なし')
    const [senderName, setSenderName] = useState('')
    const [isShowGiftRecommend, setShowGiftRecommend] = useState(true)
    const [isLargeItem, setIsLargeItem] = useState(false)
    const { dispatch } = useContext(DrawerContext)

    useEffect(() => {
        isLargeProductInclude
            ? setIsLargeItem(true)
            : setIsLargeItem(false)
    })

    const goPaymentPage = (): void => {
        // @ts-ignore
        window.open(store.checkout.webUrl)
    }

    const addNote = (): void => {
        const note = `
        ${deliveryDate !== null ? `配送希望日時: [${deliveryDate.getFullYear()}/${deliveryDate.getMonth() + 1}/${deliveryDate.getDate()}]` : ``}
		${deliveryTime !== '指定なし' ? `配送希望時間: [${deliveryTime}]` : ``}
		${senderName !== '' ? `ギフト贈り主名: [${senderName}]` : ``}
		`
        addAttribute({ note: note })
    }

    // お支払へ進む
    const handleClickPaymentButton = async () => {
        const MAX_GIFTOPTION_QUANTITY = 2
        const MAX_DAILYMINERAL_QUANTITY = 8
        const DAILYMENERAL = 'DAILYMINERAL'

        // lineItemとproductItemの持つ情報を結合
        const cartItems = store.checkout.lineItems.map((lineItem) => {
            const sameProduct = store.products.find((product) => product.id === lineItem.variant.product.id)

            return { ...lineItem, ...sameProduct }
        })

        const dailyMineralQuantity = cartItems
            .filter(item => item.productType === DAILYMENERAL)
            .reduce((acc, item) => acc + item.quantity, 0)

        if ((hasItems && dailyMineralQuantity >= MAX_DAILYMINERAL_QUANTITY) && giftOptionQuantity >= MAX_GIFTOPTION_QUANTITY) {
            window.alert('◆１注文あたりのDAILYMINERALシリーズの最大購入点数は7点までとなります。\n◆１注文あたりのギフトオプションの最大購入点数は１点までとなります。')
        } else if (hasItems && dailyMineralQuantity >= MAX_DAILYMINERAL_QUANTITY) {
            window.alert('◆１注文あたりのDAILYMINERALシリーズの最大購入点数は7点までとなります。')
        } else {
            addNote()
            goPaymentPage()
        }
    }

    const isGiftInclude = useMemo(() => store.checkout.lineItems.filter(item => item.title === GIFT_TITLE).length !== 0, [store.checkout.lineItems])
    const isLargeProductInclude = useMemo(() => store.checkout.lineItems.some(item => item.variant.product.handle.includes('large')), [store.checkout.lineItems])

    const handleGiftOptionButton = (): void => {
        dispatch({ type: 'CLOSE' })
        navigate('/products/gift/')
    }

    const handleSenderName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setSenderName(e.target.value)
    }, [])

    const handleDeliveryDate = useCallback((date: Date) => {
        setDeliveryDate(date)
    }, [])

    const handleDeliveryDateReset = useCallback(() => {
        setDeliveryDate(null)
    }, [])

    const handleDeliveryTimeForm = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        setDeliveryTime(e.target.value as DeliveryTimeOptions)
    }, [])

    const isShowDeliveryForm = (): boolean => useMemo(() => {
        const MIN_QUANTITY = 2
        // 商品が二個以上もしくは大容量パックが含まれているとき
        return (hasItems && quantity >= MIN_QUANTITY) || isLargeProductInclude
    }, [hasItems, quantity])

    // カートを空にする
    const handleRemoveAllItems = (): void => {
        removeAllItems()
    }

    // ギフトいかがでしょうを非表示にする
    const handleRemoveGiftRecommend = (): void => {
        setShowGiftRecommend(false)
    }
    
    return (
        <div css={container} {...props}>
            <div css={body}>
                <div css={heading}>
                    <Typography variant='h5' color='textPrimary' component='p'>ショッピングカート</Typography>
                    <Typography variant='h5' color='textPrimary' component='p'>{quantity}</Typography>
                </div>

                <ul>
                    {store.checkout.lineItems.map(item => {
                        return (    
                            <LineItem css={list_item} item={item} key={item.id} />
                        )
                    })}
                </ul>

                <Typography component='p' onClick={handleRemoveAllItems} color='textSecondary' css={text_empty}>カートを空にする</Typography>

                <hr css={hr} />

                {/* 小計 */}
                <div css={total}>
                    <Typography component='p'>小計 <span css={total_sub_text}>税込</span></Typography>
                    {/* クーポン適用前の金額を表示 */}
                    <Typography component='p' color='textPrimary'>{Math.trunc(store.checkout.lineItemsSubtotalPrice?.amount)}円</Typography>
                </div>

                <CVButton css={payment_button} onClick={handleClickPaymentButton}>お支払いへ進む</CVButton>

                <Typography component='p' css={text_note} color='textSecondary'>
                    安全・簡単なオンラインでのお支払い<br />
                    配送料・手数料・クーポンはこの後計算されます<br />
                    小袋セット単品でのご購入は、<span css={text_note__attention}>全国送料一律¥390</span>でお届け。<br />
                    おまとめ買いや大容量パックは<span css={text_note__attention}>送料¥710~</span>から。
                </Typography>

                <hr css={option_hr} />

                {isGiftInclude && (
                    <SenderNameForm
                        handleSenderName={handleSenderName}
                        senderName={senderName}
                        placeholder='ギフトの贈り主名'
                    />
                )}

                {isShowDeliveryForm() && (
                    <>
                        <DeliveryDateForm
                            css={delivery_date_form}
                            date={deliveryDate}
                            minDate={MIN_DELIVERY_DATE}
                            handleDateChange={handleDeliveryDate}
                            handleReset={handleDeliveryDateReset}
                        />
                        <DeliveryTimeForm
                            css={[delivery_time_form, deliveryTime !== '配送時間帯' && select_text]}
                            time={deliveryTime}
                            setTime={handleDeliveryTimeForm}
                            items={timeOptions}
                        />
				    <Typography component='p' css={text_note} color='textSecondary'>この注文は配送オプションを利用できます。<br />最短お届けは<span css={text_note__attention}>「希望日指定なし」</span>を選択ください。<br />また、配送時間帯のみの指定も可能です。<br /></Typography>
                        <Typography component='p' css={text_note} color='textSecondary'>＊沖縄を除く離島地域の方は追加請求が発生します。</Typography>
                        <MicroTextLink css={text_link}
                                       href='https://on-sen.zendesk.com/hc/ja/articles/360044930714'>もっと詳しく</MicroTextLink>
                    </>
                )}
            </div>

            <FooterBottom css={footer} />
        </div>
    )
}

export default DrawerMenu
