import { css } from '@emotion/core'

import { palette } from '~/utils/colors'

export default {
	containerClass: css`
		display: flex;
		align-items: center;
	`,
	leftButtonClass: css`
		border-radius: 4px 0 0 4px;
		height: 32px;
		width: 32px;
		font-size: 1.6rem;
		display: grid;
		place-items: center;
		background-color: ${palette.grey['500']};
		color: ${palette.text.secondary};
		margin-right: 1px;
		cursor: pointer;
		:hover {
			background-color: ${palette.primary.main};
			color: ${palette.common.white};
		}
	`,
	rightButtonClass: css`
		border-radius: 0 4px 4px 0;
		height: 32px;
		width: 32px;
		font-size: 1.6rem;
		display: grid;
		place-items: center;
		background-color: ${palette.grey['500']};
		color: ${palette.text.secondary};
		margin-left: 1px;
		cursor: pointer;
		:hover {
			background-color: ${palette.primary.main};
			color: ${palette.common.white};
		}
	`,
	quantityTextClass: css`
		height: 32px;
		width: 32px;
		font-size: 1.6rem;
		display: grid;
		place-items: center;
		background-color: ${palette.grey['500']};
		color: ${palette.grey['800']};
	`,
}