import React from 'react'
import Typography from '~/components/atoms/Typography'
import { container, copyright, logo, logos_container } from './styles'

import AMEX_LOGO from '~/images/logos/1_amex.svg'
import APPLEPAY_LOGO from '~/images/logos/2_applepay.svg'
import FAMILYMART_LOGO from '~/images/logos/3_familymart.svg'
import GAPY_LOGO from '~/images/logos/4_gpay.svg'
import LAWSON_LOGO from '~/images/logos/5_lawson.svg'
import LINEPAY_LOGO from '~/images/logos/6_linepay.svg'
import MASTERCARD_LOGO from '~/images/logos/7_mastercard.svg'
import MERPAY_LOGO from '~/images/logos/8_merpay.svg'
import PAYPAY_LOGO from '~/images/logos/9_paypay.svg'
import SEVENELEVEN_LOGO from '~/images/logos/10_seveneleven.svg'
import { Container } from '~/components/layout/Container'

const FooterBottom: React.VFC = ({ ...props }) => {
    return (
        <Container maxWidth={1000} css={container} {...props}>

            <Typography component='p' css={copyright} variant='subtitle2' color='textSecondary'>
                ©︎2021 Onsen* Rights Reserved
            </Typography>
        </Container>
    )
}

export default FooterBottom
