import React, { FC, useReducer } from 'react'
import { DrawerContext, DrawerProps, initialState } from '~/context/DrawerContext'


export type Action = {
	type: 'OPEN'
} | {
	type: 'CLOSE'
}

const reducer = (state: DrawerProps, action: Action) => {
	switch (action.type) {
		case 'OPEN':
			return {
				...state,
				isOpen: true,
			}
		case 'CLOSE':
			return {
				...state,
				isOpen: false,
			}
		default:
			return state
	}
}

export const DrawerProvider: FC = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState)
	return (
		<DrawerContext.Provider value={{ state, dispatch }}>
			{children}
		</DrawerContext.Provider>
	)
}