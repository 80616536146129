import { css } from '@emotion/core'

import { breakpoints } from '../../../utils/styles'
import { palette } from '~/utils/colors'

export const container = css`
	position: relative;
	padding: 12px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1024px;
	margin: 0 auto;
	@media (min-width: ${breakpoints.pc_header}px) {
		padding: 20px 0px;
	}
`

export const container_left = css`
	@media (min-width: ${breakpoints.pc_header}px) {
		width: 77%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
`

export const sm_hide = css`
	display: none;
	@media (min-width: ${breakpoints.pc_header}px) {
		display: block;
	}
`

export const pc_hide = css`
	display: block;
	@media (min-width: ${breakpoints.pc_header}px) {
		display: none;
	}
`

export const link_text = css`
	:hover {
		color: ${palette.primary.main};
	}

	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 1.6rem;
	}
`

export const brand = css`
	display: flex;
	align-items: center;
`

export const brand_logo = css`
    width: 102.22px;
	@media (min-width: ${breakpoints.pc_header}px) {
		width: 122.67px;
	}
`

export const hamburger_menu = css`
	cursor: pointer;
	@media (min-width: ${breakpoints.pc_header}px) {
		display: none;
	}
`

export const hamburger_menu_open_icon = css`
	width: 20px;
	@media (min-width: ${breakpoints.pc_header}px) {
		display: none;
	}
`

export const hamburger_menu_close_icon = css`
	width: 20px;
	@media (min-width: ${breakpoints.pc_header}px) {
		display: none;
	}
`

export const user_icon = css`
	width: 24px;
	cursor: pointer;

	:hover {
		path {
			fill: ${palette.primary.main};
		}
	}
`

export const cart_icon = css`
	cursor: pointer;

	:hover {
		path {
			fill: ${palette.primary.main};
		}
	}
`

export const cart_close_icon = css`
	width: 20px;
	@media (min-width: ${breakpoints.pc_header}px) {
		width: 25px;
	}
`

export const cart_badge = css`
	position: absolute;
	top: 10px;
	right: 10px;
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background: ${palette.primary.main};
	@media (min-width: ${breakpoints.pc_header}px) {
		top: 18px;
		right: 19px;
	}
`

export const navigation_menu = css`
	position: absolute;
	transition: left .2s;
	left: -100%;
	top: 45px;
	@media (min-width: ${breakpoints.tablet}px) {
		width: 50%;
	}
	@media (min-width: ${breakpoints.pc_header}px) {
		display: none;
	}
`

export const navigation_menu_show = css`
	left: 0;
`

export const cart_menu = css`
	display: none;
	position: absolute;
	top: 45px;
	right: 0;
	@media (min-width: ${breakpoints.tablet}px) {
		width: 50%;
	}
	@media (min-width: ${breakpoints.pc_header}px) {
        top: 66px;
        width: 400px;
		right: calc(100%/2 - 100vw/2);
	}
`

export const cart_menu_show = css`
	display: block;
`

export const shadow_area = css`
    background-color: rgba(0, 0, 0, .3);
    position: absolute;
    top: 46px;
    left: calc(-0.5*calc(100vw - 100%));
    width: 100vw;
    height: 100vh;
	@media (min-width: ${breakpoints.pc_header}px) {
		top: 67px;
	}
`