import { css } from '@emotion/core'
import { breakpoints } from '~/utils/styles'

export const container = css`
	display: inline-flex;
	align-items: center;
	
	img {
		max-width: 100%;
	}
`