import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import CHEVRON_BOTTOM from '~/images/icons/chevron-bottom.png'

export const container = css`
	padding: 7px 13px;
	background-color: ${palette.grey['500']};
	border-radius: 4px;
	background-image: url(${CHEVRON_BOTTOM});
	background-position: right 12px center;
	background-size: 20px;
	background-repeat: no-repeat;

	:hover, :focus {
		border-color: ${palette.primary.light};
	}
`

export const active = css`
`

export const label = css`
	font-size: 1.2rem;
	color: ${palette.text.secondary};
	font-weight: 500;
`

export const select_box = css`
	width: 100%;
	font-size: 1.6rem;
	font-weight: 500;
`