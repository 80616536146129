import { css } from '@emotion/core'
import { palette } from '~/utils/colors'

export const footer = css`
    background-color: ${palette.grey['500']};
`

export const header = css`
	position: sticky;
	top: 0;
    z-index: 2;
    background: ${palette.common.white};
    border-bottom: 1px solid ${palette.grey['500']};
`

export const announce_bar = css`
	background: linear-gradient(225deg, ${palette.primary.light} 0%, ${palette.primary.dark} 100%);
`