import React, { ComponentPropsWithRef } from 'react'
import { css } from '@emotion/core'
import { base } from './styles'

interface ContainerProps extends ComponentPropsWithRef<'div'> {
    maxWidth?: number
}

export const Container = ({ maxWidth = 1000, children, ...props }: ContainerProps): JSX.Element => {
    const container = css`
		${base};
		max-width: ${maxWidth}px;
	    @media (min-width: ${maxWidth}px) {
		    padding: 0;
	    }
    `

    return (
        <div css={container} {...props}>{children}</div>
    )
}