import { css } from '@emotion/core'
import { palette } from '~/utils/colors'

export const container = css`
	background-color: ${palette.grey['500']};
	padding: 13px 12px;
	border: 1px solid ${palette.grey['500']};
	border-radius: 4px;

	:hover, :focus {
		border-color: ${palette.primary.light};
	}
`

export const active = css`
	padding-top: 3px;
	padding-bottom: 3px;
`

export const label = css`
	font-size: 1.2rem;
	color: ${palette.text.secondary};
	font-weight: 500;
`

export const input = css`
	font-size: 1.6rem;
	color: ${palette.text.primary};
	font-weight: 500;
	width: 100%;

	::placeholder {
		color: ${palette.text.secondary};
		font-weight: 500;
	}
`