import React from 'react'
import Client from 'shopify-buy'
import { Store } from '~/provider/ContextProvider'

export type ContextProps = {
	store: Store,
	addVariantToCart: (variantId: string | number, quantity: string) => void,
	removeLineItem: (client: Client.Client, checkoutID: string, lineItemID: string) => void,
	updateLineItem: (client: Client.Client, checkoutID: string, lineItemID: string, quantity: string) => void,
	addAttribute: (content: {note: string}) => void,
	addGift: () => void,
	removeGift: () => void,
	removeAllItems: () => void,
}

const StoreContext = React.createContext({} as ContextProps)

export default StoreContext
