import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import AppRecommendBanner from '~/components/molecules/AppRecommendBanner'
import SNSDualLink from '~/components/molecules/SNSDualLink'
import Typography from '~/components/atoms/Typography'
import BrandLogo from '~/components/molecules/BrandLogo'

import {
    container,
    link_container,
    text_link,
    app_recommend_banner,
    sns_container,
    sns_dual_link,
    brand_logo, flex_container, flex_right_container
} from './styles'
import { Container } from '~/components/layout/Container'

const Footer: React.VFC = ({ ...props }) => {
    const { app_recommend_banner_img } = useStaticQuery<GatsbyTypes.FooterQuery>(graphql`
        query Footer {
            app_recommend_banner_img: file(relativePath: {eq: "app-recommend-banner-img.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 500) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
        }
    `)

    return (
        <Container maxWidth={1000} css={container} {...props}>
            <BrandLogo css={brand_logo} />

            <div css={flex_container}>
                <div>
                    <div css={app_recommend_banner}>
                        <AppRecommendBanner
                            fluid={app_recommend_banner_img.childImageSharp.fluid}
                        />
                    </div>

                    <div css={sns_container}>
                        <Typography variant='h6' color='textPrimary'>公式SNS</Typography>
                        <SNSDualLink css={sns_dual_link} />
                    </div>
                </div>

                <div css={flex_right_container}>
                    <div css={link_container}>
                        <Typography variant='h6' color='textPrimary'>プロダクト</Typography>
                        <Link css={text_link} to='/pages/app'>入浴アプリ Onsen*</Link>
                    </div>

                    <div css={link_container}>
                        <Typography variant='h6' color='textPrimary'>私たちについて</Typography>
                        <Link css={text_link} to='/story'>ストーリー</Link>
                        <Link css={text_link} to='/terms'>利用規約</Link>
                        <Link css={text_link} to='/privacy'>プライバシーポリシー</Link>
                    </div>

                    <div css={link_container}>
                        <Typography variant='h6' color='textPrimary'>サポート</Typography>
                        <Link css={text_link} to='/times'>ニュース</Link>
                        <a css={text_link} href='https://bizonsen.studio.site/'>法人様向け健康経営サービス</a>
                        <a css={text_link} href='https://medicalonsen.studio.site/'>医療機関向けサービス</a>
                        <a css={text_link} href='https://on-sen.zendesk.com/hc/ja'>ヘルプセンター</a>
                        <a css={text_link} href='https://on-sen.zendesk.com/hc/ja/requests/new'>お問い合わせ</a>
                        <a css={text_link} href='https://twitter.com/onsen_cure'>公式Twitter</a>
                        <a css={text_link} href='https://note.com/onsen_cure'>公式note</a>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Footer
