import React, { Dispatch, createContext } from 'react'
import { Action } from '~/provider/DrawerProvider'

export type DrawerProps = {
	isOpen: boolean
}

export const initialState: DrawerProps = {
	isOpen: false,
}

type Context = {
	state: DrawerProps
	dispatch: Dispatch<Action>
}

export const DrawerContext = createContext<Context>({
	state: initialState,
	dispatch: () => {
	},
})

