import React, { useState, useEffect } from 'react'
import Client from 'shopify-buy'

import Context from '~/context/StoreContext'

export type Store = {
	adding: boolean,
	checkout?: Client.Cart,
	products: Client.Product[],
	shop: {},
}

export const GIFT_TITLE = 'ギフトオプション'

const ContextProvider = ({ children }) => {
	let initialStoreState: Store = {
		adding: false,
		checkout: {
			lineItems: [],
			checkoutUrl: '',
			completedAt: null,
			id: '',
			attrs: null,
			lineItemCount: 0,
			subtotalPrice: '',
		},
		products: [],
		shop: {},
	}

	const [store, updateStore] = useState(initialStoreState)
	let isRemoved = false

	useEffect(() => {
		const initializeCheckout = async () => {
			// Check for an existing cart.
			const isBrowser = typeof window !== 'undefined'
			const existingCheckoutID = isBrowser
				? localStorage.getItem('shopify_checkout_id')
				: null

			const setCheckoutInState = checkout => {
				if (isBrowser) {
					localStorage.setItem('shopify_checkout_id', checkout.id)
				}

				updateStore(prevState => {
					return { ...prevState, checkout }
				})
			}

			if (existingCheckoutID) {
				try {
					// Make sure this cart hasn’t already been purchased.
				} catch (e) {
					localStorage.setItem('shopify_checkout_id', null)
				}
			}

		}

		initializeCheckout()
	}, [])

	useEffect(
		() => () => {
			isRemoved = true
		},
		[],
	)

	useEffect(() => {
	}, [])

	const findGiftOption = (items) => items.filter(item => item.title === GIFT_TITLE)

	const addGift = () => {
		const giftProducts = findGiftOption(store.products)
		if (giftProducts.length === 0) return undefined
		const variantId = giftProducts[0].variants[0].id as string
	}

	const removeGift = () => {
		const giftProducts = findGiftOption(store.checkout.lineItems)
		if (giftProducts.length === 0) return undefined
		const variantId = giftProducts[0].id as string

	}

	// カートを空にする
	const removeAllItems = (): void => {
		const lineItems = store.checkout.lineItems
		const lineItemIds = lineItems.map(item => item.id)
		const checkoutId = store.checkout.id

	}

	return (
		<Context.Provider
			value={{
				store,
				addVariantToCart: (variantId, quantity) => {
					if (variantId === '' || !quantity) {
						console.error('Both a size and quantity are required.')
						return
					}

					updateStore(prevState => {
						return { ...prevState, adding: true }
					})

					const { checkout } = store

					const checkoutId = checkout.id
					const lineItemsToUpdate = [
						{ variantId, quantity: parseInt(quantity, 10) },
					]

				},
				removeLineItem: (client, checkoutID, lineItemID) => {
					return client.checkout
						.removeLineItems(checkoutID, [lineItemID])
						.then(res => {
							updateStore(prevState => {
								return { ...prevState, checkout: res }
							})
						})
				},
				updateLineItem: (client, checkoutID, lineItemID, quantity) => {
					const lineItemsToUpdate = [
						{ id: lineItemID, quantity: parseInt(quantity, 10) },
					]

					return client.checkout
						//@ts-ignore
						.updateLineItems(checkoutID, lineItemsToUpdate)
						.then(res => {
							updateStore(prevState => {
								return { ...prevState, checkout: res }
							})
						})
				},
				addAttribute: (content) => {
				},
                addGift: addGift,
                removeGift: removeGift,
				removeAllItems: removeAllItems,
			}}
		>
			{children}
		</Context.Provider>
	)
}
export default ContextProvider
