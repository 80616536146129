import React from 'react'
import { Link } from 'gatsby'

import { container } from './styles'

type Props = {
    url: string
}

const CVLink: React.FC<Props> = ({ url, children, ...props }) => {
    return (
        <Link
            css={container}
            to={url}
            {...props}
        >
            {children}
        </Link>
    )
}

export default CVLink