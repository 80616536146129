import { css } from '@emotion/core'
import { palette } from '~/utils/colors'

export const container = css`
	display: flex;
	gap: 12px;
	padding: 12px;
	border: 1px solid ${palette.grey['500']};
	border-radius: 8px;

`

export const left = css`
	width: 80px;
	height: 80px;
	background-size: cover;
	border-radius: 8px;
`

export const right = css`
    margin-left: 12px;
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: space-between;
`

export const header = css`
	display: flex;
	justify-content: space-between;
`

export const text_title = css`
	flex: 1;
`

export const text_remove = css`
    margin-left: 10px;
	text-decoration: underline;
	font-size: 1.4rem;
	cursor: pointer;
    font-weight: 500;
    min-width: 28px;

	:hover {
		color: ${palette.primary.light};
	}
`

export const variant__title = css`
    font-size: 1.2rem;
    font-weight: 500;
`

export const footer = css`
	display: flex;
	justify-content: space-between;
    align-items: flex-end;
`
