import React from 'react'
import { active, container, input, label } from './styles'

type Props = {
    handleSenderName: (e: React.ChangeEvent<HTMLInputElement>) => void
    senderName: string
    placeholder: string
}

const SenderNameForm = React.memo(({ senderName, handleSenderName, ...props }: Props) => {
    return (
        <div css={[container, senderName && active]}>
            {senderName && (<label css={label}>ギフトの贈り主名</label>)}
            <input css={input} type='text' value={senderName} onChange={handleSenderName} {...props} />
        </div>
    )
})

export default SenderNameForm
