import React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ja from 'date-fns/locale/ja'
registerLocale('ja', ja)

import './styles.css'

const CustomDatePicker = ({ ...props }) => {
    return (
        <DatePicker
            locale='ja'
            {...props}
        />
    )
}

export default CustomDatePicker