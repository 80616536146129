import { createContext, Dispatch } from 'react'

export type Action = {
    type: 'OPEN'
} | {
    type: 'CLOSE'
}

export type NavigationMenuState = {
    isOpen: boolean
}

type Context = {
    navigationMenuState: NavigationMenuState
    navigationMenuDispatch: Dispatch<Action>
}

export const initialState: NavigationMenuState = {
    isOpen: false
}

export const NavigationMenuContext = createContext<Context>({
    navigationMenuState: initialState,
    navigationMenuDispatch: () => {
    }
})