import React from 'react'
import { right, left, link, icon_twitter, icon_note } from './styles'

import TwitterIcon from '~/components/molecules/TwitterIcon'
import NoteIcon from '~/components/molecules/NoteIcon'

const SNSDualLink: React.VFC = ({ ...props }) => {
    return (
        <div {...props}>
            <a css={[link, left]} href='https://twitter.com/onsen_cure' target='_blank' rel='noopener noreferrer'>
                <TwitterIcon css={icon_twitter}/>Twitter
            </a>
            <a css={[link, right]} href='https://note.com/onsen_cure' target='_blank' rel='noopener noreferrer'>
                <NoteIcon css={icon_note}/>note
            </a>
        </div>
    )
}

export default SNSDualLink