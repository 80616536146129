import React from 'react'

import ONSEN_LOGO from '~/images/onsen-logo.svg'

import { container } from './styles'

const BrandLogo: React.VFC = ({ ...props }) => {
	return (
		<div css={container} {...props}>
			<img src={ONSEN_LOGO} alt='onsen*'/>
		</div>
	)
}

export default BrandLogo