import React, { useContext } from 'react'
import { container, list, list_item, list_item_dual, list_link, list_text, note_icon, twitter_icon } from './styles'
import { Link } from 'gatsby'

import TwitterIcon from '~/components/molecules/TwitterIcon'
import NoteIcon from '~/components/molecules/NoteIcon'
import { NavigationMenuContext } from '~/context/NavigationMenuContext'

const NavigationMenu: React.VFC = ({ ...props }) => {
    const { navigationMenuDispatch } = useContext(NavigationMenuContext)

    const handleCloseNavigationMenu = () => {
        navigationMenuDispatch({ type: 'CLOSE' })
    }

    return (
        <div css={container} {...props}>
            <ul css={list}>
                <li css={[list_item, list_item_dual]}>
                    <Link css={list_text} to='/pages/app' onClick={handleCloseNavigationMenu}>入浴アプリ Onsen*</Link>
                    <Link css={list_link} to='/pages/app' onClick={handleCloseNavigationMenu}>詳細</Link>
                </li>
            </ul>

            <ul css={list}>
                {/* <li css={list_item}>
                    <Link css={list_text} to='/story' onClick={handleCloseNavigationMenu}>お客様の声</Link>
                </li> */}
                <li css={list_item}>
                    <Link css={list_text} to='/story' onClick={handleCloseNavigationMenu}>ストーリー</Link>
                </li>
                <li css={list_item}>
                    <Link css={list_text} to='/times' onClick={handleCloseNavigationMenu}>ニュース</Link>
                </li>
                <li css={[list_item, list_item_dual]}>
                    <a css={list_text} href='https://twitter.com/onsen_cure' target='_blank'
                       rel='noopener noreferrer'>公式Twitter</a>
                    <TwitterIcon css={twitter_icon} />
                </li>
                <li css={[list_item, list_item_dual]}>
                    <a css={list_text} href='https://note.com/onsen_cure' target='_blank'
                       rel='noopener noreferrer'>公式note</a>
                    <NoteIcon css={note_icon} />
                </li>
            </ul>

            <ul css={list}>
                <li css={list_item}>
                    <a css={list_text} href='https://bizonsen.studio.site/'>法人様向け健康経営サービス</a>
                </li>
                <li css={list_item}>
                    <a css={list_text} href='https://medicalonsen.studio.site/'>医療機関向けサービス</a>
                </li>
                <li css={list_item}>
                    <a css={list_text} href='https://on-sen.zendesk.com/hc/ja'>ヘルプセンター</a>
                </li>
                <li css={list_item}>
                    <a css={list_text} href='https://on-sen.zendesk.com/hc/ja/requests/new'>お問い合わせ</a>
                </li>
            </ul>
        </div>
    )
}

export default NavigationMenu
