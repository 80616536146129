import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import CHEVRON_BOTTOM from '~/images/icons/chevron-bottom.png'

export const container = css`
    padding: 7px 13px 5.36px 13px;
	border: 1px solid ${palette.grey['500']};
	background-color: ${palette.grey['500']};
	border-radius: 4px;
	background-image: url(${CHEVRON_BOTTOM});
	background-position: right 12px center;
	background-size: 20px;
	background-repeat: no-repeat;

	:hover, :focus {
		border-color: ${palette.primary.light};
	}
`

export const active = css`
    background-image: none;
`

export const label = css`
	font-size: 1.2rem;
	color: ${palette.text.secondary};
	font-weight: 500;
`

export const custom_input = css`
    font-size: 1.6rem;
    font-weight: 500;
    width: 100%;
    line-height: 20.5px;
    color: ${palette.text.primary};
`