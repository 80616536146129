import { css } from '@emotion/core'
import { breakpoints } from '~/utils/styles'
import { palette } from '~/utils/colors'

export const container = css`
	padding-top: 20px;
    padding-bottom: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		padding-top: 40px;
        padding-bottom: 40px;
	}
`

export const link_container = css`
	margin-top: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-right: 20px;
	}
	@media (min-width: ${breakpoints.pc}px) {
		margin-right: 40px;
	}
`

export const text_link = css`
	margin-top: 10px;
	display: block;
	font-size: 1.6rem;
	line-height: 20px;
	color: ${palette.text.secondary};
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 20px;
	}
    :hover {
        color: ${palette.primary.light};
    }
`

export const brand_logo = css`
	margin-top: 20px;
	width: 167px;
	@media (min-width: ${breakpoints.pc}px) {
		margin-top: 16px;
		width: 240px;
	}
`

export const app_recommend_banner = css`
	margin-top: 20px;
	@media (min-width: ${breakpoints.pc}px) {
		margin-top: 0;
		width: 324px;
	}
`

export const sns_container = css`
	margin-top: 40px;
`

export const sns_dual_link = css`
	margin-top: 10px;
`

export const flex_container = css`
	@media (min-width: ${breakpoints.pc}px) {
		margin-top: 40px;
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
	}
`

export const flex_right_container = css`
	@media (min-width: ${breakpoints.pc}px) {
		display: flex;
	}
`