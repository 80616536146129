import { TypographyOptions } from '@material-ui/core/styles/createTypography'

// material-ui theme
export const typography: TypographyOptions = {
	fontSize: 10,
	fontFamily: [
		'Noto Sans JP',
		'sans-serif',
	].join(','),
	h2: {
		fontSize: '3.2rem',
		fontWeight: 'bold',
		lineHeight: '128%',
		'@media (min-width: 1125px)': {
			fontSize: '4rem',
		},
	},
	h3: {
		fontSize: '2.8rem',
		fontWeight: 'bold',
		lineHeight: '128%',
		'@media (min-width: 1125px)': {
			fontSize: '3.2rem',
		},
	},
	h4: {
		fontSize: '2.4rem',
		fontWeight: 'bold',
		lineHeight: '128%',
		'@media (min-width: 1125px)': {
			fontSize: '2.8rem',
		},
	},
	h5: {
		fontSize: '2rem',
		fontWeight: 'bold',
		lineHeight: '128%',
		'@media (min-width: 1125px)': {
			fontSize: '2rem',
		},
	},
	h6: {
		fontSize: '1.6rem',
		fontWeight: 'bold',
		lineHeight: '128%',
		'@media (min-width: 1125px)': {
			fontSize: '1.6rem',
		},
	},
	subtitle1: {
		fontSize: '1.6rem',
		fontWeight: 'bold',
		lineHeight: '128%',
		'@media (min-width: 1125px)': {
			fontSize: '2rem',
		},
	},
	subtitle2: {
		fontSize: '1.4rem',
		lineHeight: '128%',
		fontWeight: 'bold',
	},
	body1: {
		fontSize: '1.6rem',
		fontWeight: 'bold',
		lineHeight: '160%',
		'@media (min-width: 1125px)': {
			fontSize: '2rem',
		},
	},
	body2: {
		fontSize: '1.4rem',
		lineHeight: '160%',
		fontWeight: 'bold',
	}
}