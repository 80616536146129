// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-collections-dailymineral-tsx": () => import("./../../../src/pages/collections/dailymineral.tsx" /* webpackChunkName: "component---src-pages-collections-dailymineral-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-orders-tsx": () => import("./../../../src/pages/orders.tsx" /* webpackChunkName: "component---src-pages-orders-tsx" */),
  "component---src-pages-pages-app-tsx": () => import("./../../../src/pages/pages/app.tsx" /* webpackChunkName: "component---src-pages-pages-app-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-products-gift-tsx": () => import("./../../../src/pages/products/gift.tsx" /* webpackChunkName: "component---src-pages-products-gift-tsx" */),
  "component---src-pages-products-wholesale-tsx": () => import("./../../../src/pages/products/wholesale.tsx" /* webpackChunkName: "component---src-pages-products-wholesale-tsx" */),
  "component---src-pages-story-tsx": () => import("./../../../src/pages/story.tsx" /* webpackChunkName: "component---src-pages-story-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-times-index-tsx": () => import("./../../../src/pages/times/index.tsx" /* webpackChunkName: "component---src-pages-times-index-tsx" */),
  "component---src-pages-times-preview-index-tsx": () => import("./../../../src/pages/times/preview/index.tsx" /* webpackChunkName: "component---src-pages-times-preview-index-tsx" */),
  "component---src-templates-sento-page-index-tsx": () => import("./../../../src/templates/SentoPage/index.tsx" /* webpackChunkName: "component---src-templates-sento-page-index-tsx" */),
  "component---src-templates-times-category-index-tsx": () => import("./../../../src/templates/times/category/index.tsx" /* webpackChunkName: "component---src-templates-times-category-index-tsx" */),
  "component---src-templates-times-keyword-index-tsx": () => import("./../../../src/templates/times/keyword/index.tsx" /* webpackChunkName: "component---src-templates-times-keyword-index-tsx" */),
  "component---src-templates-times-news-tsx": () => import("./../../../src/templates/times/news.tsx" /* webpackChunkName: "component---src-templates-times-news-tsx" */),
  "component---src-templates-times-page-index-tsx": () => import("./../../../src/templates/times/page/index.tsx" /* webpackChunkName: "component---src-templates-times-page-index-tsx" */)
}

