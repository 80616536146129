import { css } from '@emotion/core'
import { palette } from '~/utils/colors'

export const link = css`
	padding: 13px 0;
	width: calc(50% - 0.5px);
	display: inline-flex;
    align-items: center;
    justify-content: center;
	background-color: ${palette.common.white};
    color: ${palette.primary.main};
    :hover {
        color: ${palette.primary.light};
    }
`

export const left = css`
    margin-right: 1px;
    border-radius: 22px 0 0 22px;
    
`
export const right = css`
    border-radius: 0 22px 22px 0;
`

export const icon_twitter = css`
    font-size: 20px;
    margin-right: 1rem;
`

export const icon_note = css`
    font-size: 20px;
    margin-right: 1rem;
`