import { css } from '@emotion/core'
import { palette } from '~/utils/colors'

export const container = css`
    width: 100%;
    padding-top: 100%;
    position: relative;
`

export const square = css`
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
`

export const content = css`
	padding: 20px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: 12px;
	overflow: hidden;
	::before, ::after {
		background-color: rgba(0, 0, 0, 0.2);
		background-blend-mode: darken;
	}
`

export const text_title = css`
    color: ${palette.common.white};
`