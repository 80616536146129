const colors = {
	grey: '#AAB1B1',
	grey_2: '#f2f2f2',
	grey_3: '#e4eded',
	grey_4: '#919eab',
	black: '#343d3d',
	teal: '#47C9BB',
	teal_dark: '#1f8cb4',
	teal_2: '#5bcabe',
	white: '#ffffff',
}
// material-ui theme
// こちらを使っていく
export const palette = {
	common: {
		black: '#000',
		white: '#fff',
	},
	primary: {
		main: '#3BB7A9',
		light: '#47c9bb',
		dark: '#1f8cb4',
	},
	secondary: {
		main: '#47c9bb',
		dark: '#29b0a1',
	},
	text: {
		primary: '#3c4043',
		secondary: '#98A6B5',
	},
	grey: {
		200: '#e8e8e8',
		300: '#CDD6DD',
		400: '#e0e5e9',
		500: '#f0f3f5',
		600: '#DFE5EA',
		700: '#b3bfc3',
		800: '#3c4043',
    900: '#B5BEBB',
    1000: '#A1A1A1',		
	},
	shadow: {
		black: 'linear-gradient(0deg, rgba(0,0,0,0.45) 0%, rgba(0,0,0,0.45) 30%, rgba(0,0,0,0.45) 100%)',
  },
	badges: {
		blue: '#1953AB',
		red: '#B73C3C'
	},
}

export default {
	globalColor: colors.black,
	leadColor: colors.grey,
	titleColor: colors.black,
	descriptionColor: colors.grey,
	linkColor: colors.teal,
	productInfoColor: colors.grey,
	productNoteColor: colors.grey_4,
	priceColor: colors.teal,
	borderOuterColor: colors.grey_2,
	borderInnerColor: colors.grey_3,
	linkTextColor: colors.white,
	linkHoverColor: colors.teal_2,
	aTagLinkColor: colors.black,
	imageBorderColor: colors.black,
	couponBackgroundColor: colors.grey_3,
	couponBatchBackgroundColor: colors.teal,
	couponBatchTextColor: colors.white,
}
