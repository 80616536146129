import React, { useContext } from 'react'
import reduce from 'lodash/reduce'

import StoreContext from '~/context/StoreContext'
import {
    brand,
    brand_name,
    container,
    cart_badge,
    container_left,
    sm_hide,
    cart_icon,
    brand_logo,
    pc_hide,
    user_icon,
    link_text,
    navigation_menu,
    navigation_menu_show,
    hamburger_menu_close_icon,
    hamburger_menu_open_icon,
    hamburger_menu,
    cart_menu,
    cart_menu_show, cart_close_icon, shadow_area
} from './styles'
import { Link } from 'gatsby'
import { DrawerContext } from '~/context/DrawerContext'
import { NavigationMenuContext } from '~/context/NavigationMenuContext'
import NavigationMenu from '~/components/organisms/NavigationMenu'
import DrawerMenu from '~/components/organisms/DrawerMenu'
import BrandLogo from '~/components/molecules/BrandLogo'

import APP_LOGO from '~/images/app-logo.svg'
import BRAND_NAME from '~/images/brand-name.svg'
import HAMBURGER_MENU_OPEN_ICON from '~/images/hamburger-menu-open.svg'
import HAMBURGER_MENU_CLOSE_ICON from '~/images/hamburger-menu-close-icon.svg'


export const useQuantity = () => {
    const {
        store: { checkout }
    } = useContext(StoreContext)
    const items = checkout ? checkout.lineItems : []
    const total = reduce(items, (acc, item) => acc + item.quantity, 0)
    return [total !== 0, total]
}

type Props = {}

const Navigation: React.VFC<Props> = ({ ...props }) => {
    const [hasItems] = useQuantity()
    const { state, dispatch } = useContext(DrawerContext)
    const { navigationMenuState, navigationMenuDispatch } = useContext(NavigationMenuContext)

    const handleOpenCart = () => {
        dispatch({ type: 'OPEN' })
        handleCloseNavigationMenu()
    }

    const handleCloseCart = () => {
        dispatch({ type: 'CLOSE' })
    }

    const handleOpenNavigationMenu = () => {
        navigationMenuDispatch({ type: 'OPEN' })
        handleCloseCart()
    }

    const handleCloseNavigationMenu = () => {
        navigationMenuDispatch({ type: 'CLOSE' })
    }

    const handleLogoClick = () => {
        handleCloseCart()
        handleCloseNavigationMenu()
    }

    return (
        <div css={container} {...props}>
            {!navigationMenuState.isOpen ? (
                    <div css={hamburger_menu} onClick={handleOpenNavigationMenu}>
                        <img css={hamburger_menu_open_icon} src={HAMBURGER_MENU_OPEN_ICON} alt='' />
                    </div>
                )
                : (
                    <div css={hamburger_menu} onClick={handleCloseNavigationMenu}>
                        <img css={hamburger_menu_close_icon} src={HAMBURGER_MENU_CLOSE_ICON} alt='' />
                    </div>
                )
            }

            <Link css={brand} to='/' onClick={handleLogoClick}>
                <BrandLogo css={brand_logo}/>
            </Link>
            <div css={container_left}>
                <Link to='/pages/app' css={[sm_hide, link_text]}>入浴アプリ Onsen*</Link>
                {/* <Link to='/app' css={[sm_hide, link_text]}>お客様の声</Link> */}
                <Link to='/story' css={[sm_hide, link_text]}>ストーリー</Link>
                <Link to='/times' css={[sm_hide, link_text]}>ニュース</Link>
                <a
                    css={[sm_hide, link_text]}
                    href='https://bizonsen.studio.site/'
                >
                    法人のお客様
                </a>
            </div>

            <NavigationMenu
                css={[navigation_menu, navigationMenuState.isOpen && navigation_menu_show]}
            />

            <DrawerMenu
                css={[cart_menu, state.isOpen && cart_menu_show]}
            />

            {(navigationMenuState.isOpen || state.isOpen) && (
                <div css={shadow_area} onClick={handleCloseCart} />
            )}
        </div>
    )
}

export default Navigation
