import { css } from '@emotion/core'
import { palette } from '~/utils/colors'

export const container = css`
	height: calc(100vh - 44px);
	width: 100%;
	z-index: 2;
	background: ${palette.common.white};
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	padding: 20px 20px 123px 20px;
`

export const list = css`
	margin-top: 10px;
`

export const list_item = css`
	cursor: pointer;
	background-color: ${palette.grey['500']};
	margin-bottom: 1px;
    
    & > * {
	    padding: 13px 12px;
    }

	:first-of-type {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}

	:last-of-type {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		margin-bottom: 0;
	}

	:hover {
		color: ${palette.primary.light};
	}
`

export const list_item_dual = css`
	display: flex;
`

export const list_text = css`
	display: block;
    flex-grow: 2;
`

export const list_link = css`
	display: block;
	color: ${palette.primary.main};

	:hover {
		color: ${palette.primary.light};
	}
`

export const twitter_icon = css`
	font-size: 20px;
`

export const note_icon = css`
	font-size: 17.75px;
`