import React, { useContext, FC } from 'react'

import StoreContext from '~/context/StoreContext'
import { container, footer, header, left, right, text_remove, text_title, variant__title } from './styles'
import QuantityField from '~/components/atoms/QuantityField'
import Typography from '~/components/atoms/Typography'
import { GIFT_TITLE } from '~/provider/ContextProvider'
import { Link } from 'gatsby'
import { DrawerContext } from '~/context/DrawerContext'
import { NavigationMenuContext } from '~/context/NavigationMenuContext'

type Props = {
    item: ShopifyBuy.LineItem
}

const LineItem: FC<Props> = ({ item, ...props }) => {
    const { dispatch } = useContext(DrawerContext)
    const { navigationMenuDispatch } = useContext(NavigationMenuContext)
    const {
        removeLineItem,
        updateLineItem,
        store: { client, checkout }
    } = useContext(StoreContext)


    const handleRemove = () => {
        removeLineItem(client, checkout.id, item.id)
    }

    const handleIncrement = () => {
        const quantity = (item.quantity + 1).toString(10)
        updateLineItem(client, checkout.id, item.id, quantity)
    }

    const handleDecrement = () => {
        const quantity = (item.quantity - 1).toString(10)
        updateLineItem(client, checkout.id, item.id, quantity)
    }

    const handleCloseCart = () => {
        dispatch({ type: 'CLOSE' })
    }

    return (
        <li css={container} {...props}>
            <div css={left} style={{
                backgroundImage: `url(${item.variant.image.src})`
            }}/>
            <div css={right}>
                <div css={header}>
                    <Link to={`/products/${item.variant.product.handle}`} onClick={handleCloseCart}>
                        <Typography component='p' css={text_title} variant='subtitle2' color='textPrimary'>{item.title}</Typography>
                    </Link>
                    <Typography component='p' css={text_remove} onClick={handleRemove} color='textSecondary'>削除</Typography>
                </div>
                {item.title === GIFT_TITLE && (
                    <Typography css={variant__title} color='textSecondary'>{item.variant.title}</Typography>
                )}
                <div css={footer}>
                    <Typography component='p' variant='h6'>{item.variant.price}円</Typography>
                    <QuantityField
                        quantity={item.quantity.toString(10)}
                        onLeftButtonClick={handleDecrement}
                        onRightButtonClick={handleIncrement}
                    />
                </div>
            </div>
        </li>
    )
}

export default LineItem
