import React from 'react'
import { active, container, custom_input, label } from './styles'
import CustomDatePicker from '~/components/molecules/CustomDatePicker'

type Props = {
    date: Date | null
    minDate: Date
    handleDateChange: (date: Date) => void
    handleReset: () => void
}

const DeliveryDateForm = React.memo(({ date, minDate, handleDateChange, handleReset, ...props }: Props) => {
    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <button css={custom_input} ref={ref} onClick={onClick}>
            {value ? value : '指定なし'}
        </button>
    ))

    return (
        <div css={[container, date && active]} {...props}>
            <label htmlFor='deliveryDate' css={label}>
                配送希望日
            </label>
            <CustomDatePicker
                id='deliveryDate'
                dateFormat="yyyy/MM/dd"
                selected={date}
                onChange={handleDateChange}
                minDate={minDate}
                customInput={<CustomInput/>}
                isClearable={true}
            />
        </div>
    )
})

export default DeliveryDateForm